import { useState, useEffect, useRef } from 'react'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger.js'
import { useGSAP } from '@gsap/react'

gsap.registerPlugin(useGSAP, ScrollTrigger)

import props from 'prop-types'

import arrow from '@/assets/images/vector/arrow-long.svg'
import logo from '@/assets/images/vector/logo.svg'

import './FirstScreenSection.scss'
import { Linear } from 'gsap/gsap-core'
import { BrowserView, MobileView, isMobile, isDesktop } from 'react-device-detect'

export default function FirstScreenSection() {
	const [isCanLoadLoc, setCanLoadLoc] = useState(props.isCanLoad)
	const firstSect = useRef()
	const canvasElem = useRef()
	const sequenceFrames = {
		frame: 1,
	}
	let currentImagesLoaded = 0
	let picturesLoaded = false
	const startFrame = 1
	const endFrame = 140
	const frameCount = endFrame - startFrame
	let imagesArray = window.imagesArrayTopScreen
	let canvas
	let context
	let picturesToLoadCount = endFrame+1
	let thisTLRender
	function render() {
		if(context){
			context.clearRect(0, 0, canvas.width, canvas.height)
			context.drawImage(imagesArray[sequenceFrames.frame], 0, 0)
		}

	}
	useEffect(() => {
		canvas = canvasElem.current
		context = canvasElem.current.getContext("2d")
		canvas.width = 1714
		canvas.height = 1024
		thisTLRender.play()
		console.log(`useeffect`)

	}, []);
	useGSAP(
		() => {
			console.log(`usegsap`)
			// use selectors...


			thisTLRender = gsap.timeline({
				paused: true,
				repeat: -1,
				onUpdate: render,
			})
			thisTLRender.fromTo(
				sequenceFrames,
				{ frame: () => 1 },
				{ frame: () => frameCount, snap: "frame", duration:4, ease: Linear.easeNone },
				0,
			)

			if(isDesktop){
				let thisTL = gsap.timeline({
					paused: true,
					scrollTrigger: {
						start: 0,
						end: () => '+=' + window.innerHeight,
						scrub: true,
						invalidateOnRefresh: true,
						pin: '.sect-holder',
						onToggle: (self) => {
							console.log(self.progress)
							if (self.progress < 1) {
								thisTLRender.play()
							} else {

								thisTLRender.pause()
							}
						}
					}
				})


				thisTL.fromTo(
					'.sect-content',
					{ yPercent:0},
					{  yPercent: -50, ease: Linear.easeNone },
					0
				)
				thisTL.fromTo(
					'.sect-bg',
					{ opacity: 1 },
					{ opacity: 0, ease: Linear.easeNone },
					0
				)
			}



		},
		{ scope: firstSect }
	) // <-- scope for selector text (optional)


	return (
		<>
			<div className="first-screen-section" ref={firstSect}>
				<div className="sect-holder">
					<div className="sect-content">
						<div className="container">
							<div className="top-content">
								<div className="logo-box">
									<span>ganntec</span>
									<i><img src={logo} alt="" /></i>
								</div>
							</div>
							<div className="main-content">
								<div className="main-textbox">
									<h1>The Ultimate Defi <br />Trading Platform</h1>
									<a href="#" className="launch-btn" >
										<span>launch app</span>
										<i><em><b></b></em></i>
									</a>
								</div>
							</div>

						</div>
					</div>
					<div className="sect-bg">
						<div className="bg-holder">
							<div className="video">
								<canvas ref={canvasElem} className="js_xsNoChangeVH"></canvas>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}
