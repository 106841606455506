import { useState } from 'react'
import headerLogo from '@/assets/images/vector/logo.svg'
import arrowConnect from '@/assets/images/vector/arrow-connect.svg'
import socGit from '@/assets/images/vector/soc-git.svg'
import socTelegram from '@/assets/images/vector/soc-tg.svg'
import socX from '@/assets/images/vector/soc-x.svg'
import socDiscord from '@/assets/images/vector/soc-discord.svg'
import socMedium from '@/assets/images/vector/soc-medium.svg'
import socGitbook from '@/assets/images/vector/soc-git.svg'
import { ScrollSmoother } from 'gsap/ScrollSmoother.js'
import './Footer.scss'
import logo from '@/assets/images/vector/logo.svg'
import { gsap } from 'gsap/gsap-core'

function Footer() {
	function handleMenuLinkClick(elemname) {
		let previouslyCreatedSmoother = ScrollSmoother.get()
		previouslyCreatedSmoother.scrollTo(elemname, true)
		gsap.to(previouslyCreatedSmoother, {
			// don't let it go beyond the maximum scrollable area
			scrollTop: previouslyCreatedSmoother.offset(elemname, 0),
			duration: 1
		})
	}
  return (
		<>
			<footer>
				<div className="container">
					<div className="top-row">
						<div className="left-col">
							<a href="#" className="logo-box">
								<span>ganntec</span>
								<i>
									<img src={logo} alt="" />
								</i>
							</a>
						</div>
						<ul className="footer-nav">
							<li className="home">
								<a href="#home" onClick={() => {
									handleMenuLinkClick('.first-screen-section')
								}}>Home
								</a>
							</li>
							<li>
								<a href="#" onClick={() => {
									handleMenuLinkClick('.features-section')
								}}>Overview</a>
							</li>
							<li>
								<a href="#" onClick={() => {
									handleMenuLinkClick('.showcase-section')
								}}>Showcase</a>
							</li>
							<li>
								<a href="#" onClick={() => {
									handleMenuLinkClick('.overview-section')
								}}>Features</a>
							</li>
							<li>
								<a href="#" onClick={() => {
									handleMenuLinkClick('.levels-section')
								}}>Plans</a>
							</li>
							<li>
								<a href="#" onClick={() => {
									handleMenuLinkClick('.referrals-section')
								}}>Referrals</a>
							</li>
							<li>
								<a href="https://apply.workable.com/ganntec/" target="_blank">Careers</a>
							</li>
						</ul>
						<div className="right-col">
							<span className="soc-name">Socials</span>
							<ul className="soc-list">
								<li><a href="https://t.me/GanntecPortal"><img src={socTelegram} alt="" /></a></li>
								<li><a href="https://x.com/Ganntec_"><img src={socX} alt="" /></a></li>
								<li><a href="https://discord.gg/Rb3zKa2Unb"><img src={socDiscord} alt="" /></a></li>
								<li><a href="https://docs.ganntec.com/"><img src={socGitbook} alt="" /></a></li>
								<li><a href="https://medium.com/@ganntec"><img src={socMedium} alt="" /></a></li>
							</ul>
						</div>
					</div>
					<div className="bottom-row">
						<div className="copy-col">
							<span className="copy">© 2024 GANNTEC LLC, 0077886 <br />All rights reserved</span>
						</div>
						<div className="center-col">
							<ul>
								<li><a href="#">Disclamer</a></li>
								<li><a href="#">Privacy Policy</a></li>
								<li><a href="#">Terms and conditions</a></li>
							</ul>
						</div>
					</div>
				</div>
			</footer>
		</>
	)
}

export default Footer
